import "./candles.css";

let randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

let candles = [];
for (let i = 0; i < 19; i++) {
  let candle = {
    id: i,
    left: 3 + i * 4.95,
    height: randomIntFromInterval(30, 200) / 10,
    bottom: 0,
    dark: false,
  };
  if (i !== 0) {
    candle.bottom =
      candles[i - 1].bottom + randomIntFromInterval(-50, 150) / 10;
    if (candle.bottom > candles[i - 1].bottom + candles[i - 1].height) {
      candle.bottom = candles[i - 1].bottom + candles[i - 1].height;
    } else if (candle.bottom + candle.height < candles[i - 1].bottom) {
      candle.bottom = candles[i - 1].bottom;
    }
    if (
      candle.bottom + candle.height <
      candles[i - 1].bottom + candles[i - 1].height
    ) {
      candle.dark = true;
    }
  }

  candles.push(candle);
}

function Candles() {
  return (
    <div className="container page-loaded">
      <div className="bar-graph">
        {candles.map((candle) => {
          return (
            <div
              key={candle.id}
              className={["bar", ...(candle.dark ? ["dark"] : [])].join(" ")}
              style={{
                left: candle.left + "%",
                height: candle.height + "%",
                bottom: candle.bottom + "%",
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
}

export default Candles;
