import React, { useEffect } from "react";
import Candles from "./Candles";
import Statement from "./Statement";

const PARAMS = new URLSearchParams(window.location.search);
const TOKEN = PARAMS.get("token");

function App() {
  return (
    <div className="App">
      <div className="title">
        letchford <br></br>capital
      </div>
      {TOKEN ? (
        <Statement token={TOKEN}></Statement>
      ) : (
        <>
          <div className="caption" style={{ margin: "7% 30% auto auto" }}>
            coming
          </div>
          <div className="caption" style={{ margin: "1% 28% auto auto" }}>
            soon...
          </div>
        </>
      )}
      <Candles></Candles>
    </div>
  );
}

export default App;
