import React, { useEffect } from "react";
import Candles from "./Candles";

const PARAMS = new URLSearchParams(window.location.search);
const TOKEN = PARAMS.get("token");
const MINUS = PARAMS.get("minus");

const AUDDollar = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

function DataRow({ text: text, value: value }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "0 0 20px 0",
      }}
    >
      <div style={{ margin: "0 0 0 10px", fontSize: "0.7em" }}>{text}</div>
      <div style={{ margin: "0 10px 0 0", fontSize: "0.7em" }}>
        {AUDDollar.format(value)}
      </div>
    </div>
  );
}

function Separator({ marginTop = 10, marginBottom = 10 }) {
  return (
    <div
      style={{
        margin: `${marginTop}px 0 ${marginBottom}px 0`,
        borderTop: "1px solid white",
        opacity: 0.1,
      }}
    />
  );
}

function Statement(props) {
  const [currentYear, setCurrentYear] = React.useState(null);
  const [currentMonth, setCurrentMonth] = React.useState(null);
  const [nav, setNav] = React.useState(null);
  const [lastMonthClosingNav, setLastMonthClosingNav] = React.useState(null);

  const [data, setData] = React.useState(null);

  useEffect(() => {
    fetch("https://api.letchford.capital/v4/status/nav", {
      method: "GET",
      headers: {
        Authorization: `Basic ${TOKEN}`,
      },
    }).then((res) => {
      res.json().then((data) => {
        setNav(data.nav);
        if (MINUS) {
          setLastMonthClosingNav(MINUS);
        }

        setData(data);
      });
    });
  }, []);

  useEffect(() => {
    if (data) {
      let currentDate = new Date();

      setCurrentYear(currentDate.getFullYear());
      setCurrentMonth(currentDate.toLocaleString("en-AU", { month: "long" }));
    }
  }, [data]);

  return (
    <div
      className="statement"
      style={{
        margin: "0 auto",
        marginTop: "50px",
        display: "flex",
        flexDirection: "column",
        maxWidth: "640px",
        width: "100%",
        fontSize: "2em",
        fontWeight: "bold",
        color: "white",
      }}
    >
      {data && (
        <>
          <div>
            <div>
              <div
                style={{
                  margin: "0 0 20px 10px",
                  fontSize: "1em",
                  textAlign: "center",
                }}
              >
                {lastMonthClosingNav ? `${currentYear} ${currentMonth}` : "NAV"}
              </div>
              <div
                style={{
                  margin: "0 10px 0 0",
                  fontSize: "1.5em",
                  textAlign: "center",
                }}
              >
                {lastMonthClosingNav
                  ? AUDDollar.format(nav - lastMonthClosingNav)
                  : AUDDollar.format(nav)}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Statement;
